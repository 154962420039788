// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-creation-template-creation-template-js": () => import("./../../../src/templates/creation-template/creation-template.js" /* webpackChunkName: "component---src-templates-creation-template-creation-template-js" */),
  "component---src-templates-portfolio-template-portfolio-template-js": () => import("./../../../src/templates/portfolio-template/portfolio-template.js" /* webpackChunkName: "component---src-templates-portfolio-template-portfolio-template-js" */),
  "component---src-templates-service-template-service-template-js": () => import("./../../../src/templates/service-template/service-template.js" /* webpackChunkName: "component---src-templates-service-template-service-template-js" */)
}

